.send-frames-to-lesson {
    label {
        margin-left: 5px;
    }

    .label-sub {
        margin-left: 5px;
        font-size: 12px;
    }

    .error {
        color: $COLOR_ANSWER_INCORRECT;
        font-size: 20px;
    }

    .options-container,
    .frame-range-container,
    .target-lesson-container {
        padding: 20px;
    }

    .frame-range-container {
        select {
            margin: 0 10px;
        }
    }

    button[name="copy_frames"] {
        position: absolute;
        right: 20px;
        bottom: 20px;
    }
}
