.editor-hints {
    user-select: text !important;

    dt {
        font-weight: $FONT_WEIGHT_BOLD;
    }

    dd > a {
        color: #337cb7;
    }

    ul {
        margin-top: -10px;
    }

    code {
        color: $COLOR_V3_EGGPLANT;
        strong {
            font-weight: normal;
            color: $COLOR_V3_RED;
        }
    }

    .preview-lesson-text {
        color: $COLOR_V3_PURPLE;
    }

    .circle-container {
        width: 100%;
        text-align: center;
        margin-top: 20px;

        dt {
            margin-bottom: 10px;
        }
    }

    .circle {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        background-color: black;
        display: inline-block;
        margin: 5px;
        color: $COLOR_V3_WHITE !important;
        padding-top: 35px;

        &.grey {
            background-color: $COLOR_V3_BEIGE_DARKEST;
        }

        &.white {
            background-color: $COLOR_V3_WHITE;
            border: 1px solid $COLOR_V3_BEIGE_DARKEST;
            color: $COLOR_V3_BEIGE_DARKEST !important;
        }

        &.coral {
            background-color: $COLOR_V3_CORAL;
        }

        &.red {
            background-color: $COLOR_V3_RED;
        }

        &.purple {
            background-color: $COLOR_V3_PURPLE;
        }

        &.blue {
            background-color: $COLOR_V3_BLUE;
        }

        &.turquoise {
            background-color: $COLOR_V3_TURQUOISE;
        }

        &.green {
            background-color: $COLOR_V3_GREEN;
        }

        &.yellow {
            background-color: $COLOR_V3_YELLOW;
        }

        &.orange {
            background-color: $COLOR_V3_ORANGE;
        }

        &.plum {
            background-color: $COLOR_V3_PLUM;
        }

        &.darkcoral {
            background-color: $COLOR_V3_CORAL_DARK;
        }

        &.darkred {
            background-color: $COLOR_V3_RED_DARK;
        }

        &.darkpurple {
            background-color: $COLOR_V3_PURPLE_DARK;
        }

        &.darkblue {
            background-color: $COLOR_V3_BLUE_DARK;
        }

        &.darkturquoise {
            background-color: $COLOR_V3_TURQUOISE_DARK;
        }

        &.darkgreen {
            background-color: $COLOR_V3_GREEN_DARK;
        }

        &.darkyellow {
            background-color: $COLOR_V3_YELLOW_DARK;
        }

        &.darkorange {
            background-color: $COLOR_V3_ORANGE_DARK;
        }

        &.eggplant {
            background-color: $COLOR_V3_EGGPLANT;
        }
    }
}
