#context-image-panel {
    select {
        max-width: 50%;
    }

    hr {
        margin-top: 5px;
        margin-bottom: 5px;
    }
}
