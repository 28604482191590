@use "sass:math";

@import "edit_frame_list/info_panel";
@import "edit_frame_list/send_frames_to_lesson";
@import "edit_frame_list/thumbnail";
@import "edit_frame_list/editor_hints";
@import "../../lessons/show_frames_player";

@mixin edit-frame-list-actions() {
    position: fixed;
    bottom: 0px;
    padding: 10px;
    background-color: $COLOR_V3_WHITE;
    z-index: $EDIT_FRAME_LIST_ACTIONS_Z_INDEX;

    input {
        display: inline;
    }
}

@mixin edit-frame-list() {
    $borderSize: 1px;
    $borderStyle: $borderSize solid $COLOR_V3_GREY_DARK;
    $FRAMES_ACTIONS_HEIGHT: 160px;
    $MODULE_ACTIONS_HEIGHT: 93px;

    .disable_mask {
        background-color: black;
        opacity: 0.4;
    }

    .sidebar {
        display: block;
        position: absolute;
        background-color: $COLOR_V3_WHITE;

        .header-invisible & {
            top: 0px;
            // shared with .app-header so that changes happen in sync
            transition-duration: $SP_PAGE_TRANSITION_DURATION;
        }

        &.sidebar-left {
            left: 0px;
            width: $EDIT_FRAME_LIST_SIDEBAR_LEFT_WIDTH;
            background-color: $COLOR_V3_WHITE;

            &.sliding {
                left: -120px;

                &.hover {
                    left: 0px;
                }
            }

            .quick-search {
                margin: 2px;
                width: 148;
                input {
                    background-color: transparent;
                    border: 1px solid;
                    border-radius: 2px;
                    padding: 2px;
                }
            }
        }

        &.sidebar-right {
            right: 0px;
            width: $EDIT_FRAME_LIST_SIDEBAR_RIGHT_WIDTH;
            overflow-x: hidden; // otherwise causes issues with animations

            .edit-pane {
                background-color: $COLOR_V3_WHITE;
                border-left: $borderStyle;
                position: fixed;
                top: $APP_HEADER_HEIGHT;
                bottom: $MODULE_ACTIONS_HEIGHT;
                overflow-y: auto;
                overflow-x: hidden;
                width: $EDIT_FRAME_LIST_SIDEBAR_RIGHT_WIDTH;
                z-index: 1;
            }

            .frame_interaction {
                width: 140px;
                margin-right: 16px;
                float: right;
            }

            // enter animations for edit-pane and frame interaction

            .edit-pane.ng-enter,
            .frame_interaction.ng-enter {
                transition: all 0.25s ease-out;
                transform: translate3d(100%, 0, 0);
            }

            .edit-pane.ng-enter.ng-enter-active,
            .frame_interaction.ng-enter.ng-enter-active {
                transform: translate3d(0, 0, 0);
            }
        }
    }

    .tabs {
        $TAB_HEIGHT: 26px;
        $SEPARATOR_WIDTH: 1px;
        $SCROLLBAR_WIDTH: 15px;
        position: relative;
        height: $TAB_HEIGHT;

        &.tab_count_2 {
            $TAB_COUNT: 2;
            $SEPARATOR_COUNT: $TAB_COUNT - 1;

            .tab_label {
                width: math.div(
                    $EDIT_FRAME_LIST_SIDEBAR_RIGHT_WIDTH - $SCROLLBAR_WIDTH - $borderSize -
                        ($SEPARATOR_WIDTH * $SEPARATOR_COUNT),
                    $TAB_COUNT
                );
            }
        }

        &.tab_count_3 {
            $TAB_COUNT: 3;
            $SEPARATOR_COUNT: $TAB_COUNT - 1;

            .tab_label {
                width: math.div(
                    $EDIT_FRAME_LIST_SIDEBAR_RIGHT_WIDTH - $SCROLLBAR_WIDTH - $borderSize -
                        ($SEPARATOR_WIDTH * $SEPARATOR_COUNT),
                    $TAB_COUNT
                );
            }
        }

        &.tab_count_4 {
            $TAB_COUNT: 4;
            $SEPARATOR_COUNT: $TAB_COUNT - 1;
            $DEFAULT_WIDTH: math.div(
                $EDIT_FRAME_LIST_SIDEBAR_RIGHT_WIDTH - $SCROLLBAR_WIDTH - $borderSize -
                    ($SEPARATOR_WIDTH * $SEPARATOR_COUNT),
                $TAB_COUNT
            );

            .tab_label {
                width: $DEFAULT_WIDTH;
            }

            .frame_label {
                width: $DEFAULT_WIDTH + 18;
            }

            .comments_label {
                width: $DEFAULT_WIDTH + 7;
            }

            .seo_label {
                width: $DEFAULT_WIDTH - 25;
            }
        }

        .tab_label {
            height: $TAB_HEIGHT;
            line-height: $TAB_HEIGHT;
            float: left;
            text-align: center;
            border-bottom: 1px solid $COLOR_V3_GREY_DARK;
            box-shadow: inset 0px 0px 14px -4px black;
            cursor: pointer;
            color: $COLOR_V3_GREY_DARK;

            &:hover {
                line-height: $TAB_HEIGHT - 2px;
            }

            /* when pressed */
            &:active {
                box-shadow: inset 0px 0px 20px -4px black;
                font-size: 0.92em;
            }

            /* when this is the tab that is being shown */
            &.selected {
                border-bottom: 0px;
                box-shadow: none;
                color: black;
            }
        }

        .separator {
            width: 1px;
            height: $TAB_HEIGHT;
            float: left;
            background-color: $COLOR_V3_GREY_DARK;
        }
    }

    .edit-frame-list-thumbnails {
        .frames {
            position: fixed;
            z-index: 0;
            top: 108px;
            bottom: $FRAMES_ACTIONS_HEIGHT;
            overflow-y: auto;
            overflow-x: hidden;
            width: $EDIT_FRAME_LIST_SIDEBAR_LEFT_WIDTH;

            .header-invisible & {
                top: 0px;
                // shared with .app-header so that changes happen in sync
                transition-duration: $SP_PAGE_TRANSITION_DURATION;
            }
        }

        .frames_actions {
            @include edit-frame-list-actions();
            border-top: $borderStyle;
            border-right: $borderStyle;
            height: $FRAMES_ACTIONS_HEIGHT;
            width: $EDIT_FRAME_LIST_SIDEBAR_LEFT_WIDTH;
        }

        &.hide_actions {
            .frames_actions {
                display: none;
            }

            .frames {
                bottom: 0px;
            }
        }
    }

    .lesson-description-container {
        padding: 0;
    }

    .module_actions {
        @include edit-frame-list-actions();
        border-top: $borderStyle;
        border-left: $borderStyle;
        height: $MODULE_ACTIONS_HEIGHT;
        width: $EDIT_FRAME_LIST_SIDEBAR_RIGHT_WIDTH;
    }
}

edit-lesson {
    &,
    > .edit_lesson,
    > .edit_lesson > div,
    > .edit_lesson > div > form {
        display: block;
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
    }

    .edit_lesson {
        .frame_list {
            @include frames-player(
                $MAIN_CONTENT_MIN_WIDTH,
                $EDIT_FRAME_LIST_SIDEBAR_LEFT_WIDTH,
                $EDIT_FRAME_LIST_SIDEBAR_RIGHT_WIDTH
            );
            @include edit-frame-list;
            @include edit-frame-list-info-panel;

            user-select: text !important;

            > div > .lesson-content-container {
                width: auto;
                left: $EDIT_FRAME_LIST_SIDEBAR_LEFT_WIDTH;
                right: $EDIT_FRAME_LIST_SIDEBAR_RIGHT_WIDTH;
                overflow-x: hidden; // interactive cards has stuff that hangs out of the main area in the editor. Hide it
                overflow-y: auto; // allow vertical scrolling

                .editor-pref-checkboxes {
                    font-size: 14px;
                    position: absolute;
                    bottom: 0;
                    right: 10px;
                }
            }

            .front-royal-footer {
                margin-left: -$EDIT_FRAME_LIST_SIDEBAR_LEFT_WIDTH;
            }
        }
    }
}
