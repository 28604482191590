.edit-lesson-version-history {
    .version-action-modal {
        .text-click-target {
            display: none !important;
        }
        .action-icon i {
            position: relative;
            display: inline-block;
            top: 2px;
            background-color: $COLOR_V3_BEIGE_BEYOND_DARK;
            border-radius: 50%;
            border: 0px solid transparent;
            width: 15px;
            height: 15px;
            margin-right: 5px;

            &:hover {
                background-color: $COLOR_V3_EGGPLANT;
            }

            &:before {
                position: absolute;
                left: 10%;
                top: 5%;
            }
        }
    }

    .most-recent-version {
        color: $COLOR_V3_CORAL_DARK;
    }

    .published-checkmark {
        display: inline-block;
        margin-right: 4px;
        color: $COLOR_V3_BEIGE_DARKEST;

        &.currently-published {
            color: $COLOR_V3_BLACK;
        }
    }

    .version-title {
        font-weight: $FONT_WEIGHT_SEMIBOLD;
    }
    .version-description {
    }
    .version-at {
        color: $COLOR_V3_BEIGE_BEYOND_BEYOND_DARK;
    }

    .version-diff-row {
        margin-top: 5px;

        .show-version-diff,
        .hide-version-diff {
            width: calc(50% - 5px);
            display: inline-block;

            &:first-of-type {
                margin-right: 5px;
            }

            button {
                font-size: 0.8em;
                height: 50px;
                width: 100%;

                &[disabled="disabled"] {
                    opacity: 0.4;
                    pointer-events: none;
                }
            }
        }
        .version-diff-help {
            padding: 5px;
            background-color: $COLOR_V3_YELLOW;
            color: $COLOR_V3_WHITE;
            border: 0px solid transparent;
            border-radius: 5px;
            display: block;
            margin-top: 10px;
        }
    }
}
