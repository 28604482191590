@font-face {
    font-family: "ApercuPro";
    src: url("~fonts/apercu-bold-web/apercu-bold.woff2");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

.cf-challenges-editor {
    [name="challenges_list"] {
        .challenge_row {
            position: relative;
            margin: 0px 0px 10px;
            padding: 0px 0px 10px;
            border-bottom: 2px solid $COLOR_V3_BLACK;
            transition: background-color 0.25s ease;

            &.activeChallengeRow {
                background-color: #cdf0ff;
            }

            .active-challenge-radio-button {
                margin: 5px;
            }

            .correct_answer {
                float: left;
                clear: both;
                margin-right: 5px;
            }

            .cf-reorder-list-item-buttons {
                position: absolute;
                right: 0px;
                top: 0px;
                z-index: 100;
            }
        }
    }

    cf-user-defined-options {
        .radio-group {
            margin-bottom: 10px;
        }
    }
}
