.cf-answer-list-editor {
    .cf-answer-list-item {
        position: relative;
        padding-bottom: 5px;
        margin-bottom: 5px;
        border-bottom: 1px solid $COLOR_V3_GREY_LIGHT;

        .cf-reorder-list-item-buttons {
            position: absolute;
            right: 0px;
            top: 0px;
            z-index: 100;
        }

        &.correct-answer {
            [name="answer"] table {
                background-color: transparent;
            }
            [name="answer"] > *,
            [name="answer"] .table-responsive {
                background-color: rgba(0, 255, 0, 0.2);
            }
        }
    }
}
