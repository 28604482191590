$FRAME_THUMBNAIL_HEIGHT: 80px;

@mixin edit-frame-list_thumbnail() {
    $PADDING: 4px;
    $TEXT_LEFT_OFFSET: 16px;
    $WIDTH: $EDIT_FRAME_LIST_SIDEBAR_LEFT_WIDTH - 12px; // make room for the scroll bar
    $GUTTER_WIDTH: 40px; // Make room for the frame index and type
    $GUTTER_CONTENT_WIDTH: $GUTTER_WIDTH - 10px;

    background-color: $COLOR_V3_WHITE;
    overflow: hidden;
    height: $FRAME_THUMBNAIL_HEIGHT;
    width: $WIDTH;
    position: relative;
    border: 0px solid transparent;
    border-radius: 4px;
    cursor: pointer;

    .thumbnail-box {
        height: $FRAME_THUMBNAIL_HEIGHT - 4px;
        width: $WIDTH - $GUTTER_WIDTH - 2px;
        top: 2px;
        left: $GUTTER_WIDTH;
        position: relative;
        border: 1px solid rgba($COLOR_V3_BLACK, 0.25);
        border-radius: 3px;
        text-align: center;
        overflow: hidden;
    }

    &.selected .thumbnail-box {
        border: 1px solid transparent;
        box-shadow: 0px 0px 0px 2px $COLOR_V3_CORAL;
    }

    &:hover:not(.selected) .thumbnail-box {
        border: 1px solid transparent;
        box-shadow: 0px 0px 0px 1px $COLOR_V3_CORAL;
    }

    .index {
        position: absolute;
        top: 0;
        left: $PADDING;
        font-size: 18px;
        width: $GUTTER_CONTENT_WIDTH;
        text-align: center;
        text-shadow: 0px 0px 6px $COLOR_V3_WHITE;
    }

    .type {
        position: absolute;
        bottom: $PADDING - 1px;
        left: $PADDING - 1px;
        width: $GUTTER_CONTENT_WIDTH;
        font-size: 12px;
        border: 1px solid $COLOR_V3_WHITE;
        border-radius: 3px;
        background-color: $COLOR_V3_GREY_DARK;
        color: $COLOR_V3_WHITE;
        padding: 1px 4px;
        text-align: center;
        word-wrap: break-word;
        z-index: 2;

        &.MC,
        &.MCMC {
            background-color: $COLOR_V3_RED;
        }
        &.FIB,
        &.CB {
            background-color: $COLOR_V3_ORANGE;
        }
        &.BOI,
        &.CBOI,
        &.IH {
            background-color: $COLOR_V3_CORAL_DARK;
        }
        &.ToT {
            background-color: $COLOR_V3_PURPLE;
        }
        &.MA {
            background-color: $COLOR_V3_TURQUOISE_DARK;
        }
        &.PO {
            background-color: $COLOR_V3_BLUE;
        }
    }

    &.exceeds_text_limit {
        .index:after {
            content: "•";
            color: $COLOR_V3_RED;
            margin-left: 2px;
            position: relative;
            top: -1px;
        }
    }

    &.contains_search_term {
        background-color: rgba($COLOR_V3_YELLOW, 0.5);
    }

    &.selected.contains_search_term {
        background-color: rgba($COLOR_V3_YELLOW_DARK, 0.5);
    }

    .text {
        font-size: 10px;
        line-height: 1.1em;
        margin: 5px;
        height: $FRAME_THUMBNAIL_HEIGHT - 2 * $PADDING;
    }

    .main_image {
        img {
            max-height: 30px;
            max-width: $WIDTH*0.5;
            margin: 5px 0px 0px;
        }
    }

    .icons-group {
        position: absolute;
        right: 2px;
        z-index: 2;
        text-align: right;

        .icon {
            vertical-align: top;
            height: 20px;
            width: 20px;
            color: $COLOR_V3_WHITE;
            display: inline-block;
            margin-left: 2px;
            text-align: center;
        }

        &:not(:hover) {
            .frame.icon {
                display: none;
            }
        }

        &.branching {
            bottom: 2px;

            .icon {
                background-color: $COLOR_V3_CORAL_DARK;
            }
        }

        &.merging {
            top: 2px;

            .icon {
                background-color: $COLOR_V3_PURPLE;
            }
        }
    }

    .annotations-container {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;

        .annotations {
            position: absolute;
            left: 40px;
            top: 2px;
            text-align: left;
            z-index: 2;
            width: 20px;
            height: 20px;
            border-top-left-radius: 3px;
            overflow: hidden;

            .icon {
                width: 0;
                height: 0;
                border-top: 20px solid $COLOR_V3_YELLOW;
                border-right: 20px solid transparent;
            }
        }
    }
}

.annotation-tooltip {
    .tooltip-inner {
        padding: 5px 10px;
    }

    h5,
    ul,
    li {
        text-align: left;
        margin: 0;
    }

    h5 {
        margin-bottom: 5px;
    }
}

.edit_lesson .frame_list {
    .frames {
        background-color: $COLOR_V3_WHITE;
        padding: 0px;
        list-style-type: none;
        li {
            padding: 2px;
            &.sortable-ghost {
                opacity: 0.25;
            }
        }

        .frame_thumbnail_container {
            @include edit-frame-list_thumbnail();
        }
    }
}
