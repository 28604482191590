.edit-content-list-view {
    position: relative;

    .editable-things-container {
        padding: 0 !important;
    }

    #recents-panel,
    .archive-warning {
        margin-bottom: 3px !important;
    }

    button[name="lesson-archive-toggle"] {
        position: absolute;
        top: -34px;
        right: 0px;
        height: 30px;
        line-height: 18px;
    }

    button[name="search"],
    button[name="reset"] {
        margin-left: 6px;
    }

    .back-to-arrow {
        padding-left: 20px;
    }

    .edit-content-list-panel-header {
        padding: 10px;
        background-color: #f5f5f5;
        border-bottom: 1px solid #ddd;
        @media (min-width: $screen-sm-min) {
            padding: 15px;
        }
    }

    .edit-content-list-panel-body {
        padding: 10px;
        @media (min-width: $screen-sm-min) {
            padding: 15px;
        }
    }

    .selectize-control {
        display: inline-block;
        min-width: 160px;
    }

    .input-group {
        float: left;
        width: 200px;
        @media (max-width: $screen-xs-max) {
            width: 100% !important;
            float: left !important;
        }
    }

    .content-search-container {
        display: inline-block;
        margin: 0 4px;
        width: calc(100% - 85px);
        button {
            position: absolute;
            top: 0;
            right: 15px;
            width: 75px;
        }
        .input-group {
            float: right;
            width: 300px;
        }
    }

    .input-wrapper,
    .input-group {
        @media (max-width: $screen-xs-max) {
            margin: 0 !important;
        }
    }

    .editable-things-container {
        .list-header {
            padding-left: 10px;
            padding-right: 10px;
            margin-bottom: 10px;
            button[name="create"] {
                margin-bottom: 10px;
            }
        }
        .custom-tip-container {
            float: right;
            padding-right: 15px;
        }

        th,
        td {
            &:first-of-type {
                padding-left: 15px;
            }
        }
    }

    .archive-warning {
        background-color: $COLOR_V3_RED;
        color: $COLOR_V3_WHITE;
        font-size: 18px;
        text-align: center;
    }

    .panel-heading.collapsable {
        background-image: url(~vectors/arrow_right_black_thin.svg);
        background-size: 7px 13px;
        background-position: right 20px top 50%;
        background-repeat: no-repeat;
    }

    .panel-heading.collapsable:not(.collapsed) {
        background-image: url(~vectors/arrow_down_black_thin.svg);
        background-size: 13px 7px;
    }
}

editor-index {
    div.section-container {
        background-color: $COLOR_V3_BEIGE;
    }
}

.seo-fields {
    button {
        .icon {
            display: block;
            float: none;
            &.lock {
                &:before {
                    @include font-awesome-icon("\f023"); // fa-arrow-up
                }
            }

            &.unlock {
                &:before {
                    @include font-awesome-icon("\f09c"); // fa-arrow-up
                }
            }
        }
    }
}

.editor {
    .panel {
        margin: 0;
        margin-bottom: -2px;
    }
}

.sortable-list {
    font-size: 14px;

    .archived & td {
        color: $COLOR_V3_GREY_DARK;
    }

    th {
        font-size: 120%;
        cursor: default;
        border-radius: 3px 3px 0 0;

        &.is-clickable {
            cursor: pointer;

            &:hover {
                background-color: #f5f5f5;
            }
        }
    }

    th:before {
        // we need to make sure these font-awesome icons
        // are small enough so they don't affect the size
        // of the cell (see an editable things list with frozen
        // columns for why this is an issue).  Anyway, it looks better
        // when it is a bit smaller
        font-size: 10px;
    }

    .is-hideable {
        &:before {
            @include font-awesome-icon("\f068"); // fa-minus
            margin-right: 8px;
        }
    }

    .sort-asc {
        &:before {
            @include font-awesome-icon("\f062"); // fa-arrow-up
            margin-right: 8px;
        }
    }

    .sort-desc {
        &:before {
            @include font-awesome-icon("\f063"); // fa-arrow-down
            margin-right: 8px;
        }
    }

    th {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    th[data-id="tag"] {
        width: 10%;
    }

    th[data-id="title"] {
        width: 26%;
    }

    th[data-id="author-name"] {
        width: 10%;
    }

    th[data-id="sortablePublishedAt"] {
        width: 12%;
    }

    th[data-id="chapterCount"],
    th[data-id="lessonCount"],
    th[data-id="streamCount"],
    td[data-id="chapterCount"],
    td[data-id="lessonCount"],
    td[data-id="streamCount"] {
        text-align: center;
    }

    th[data-id="sortableModifiedAt"] {
        width: 12%;
    }

    th[data-id="streamTitlesToS"] {
        width: 22%;
    }

    th[data-id="editorAbilities"] {
        width: 8%;
        text-align: center;
    }

    td[data-id="editorAbilities"] {
        white-space: nowrap;
        color: black;

        text-align: center;

        button {
            margin: 0px 1px;
            &:after {
                color: $COLOR_V3_WHITE;
                font-size: 1.4em;
            }
        }

        button[data-id="add"] {
            padding: 5px 7px;
            &:after {
                @include font-awesome-icon("\f067"); // fa-plus
            }
        }

        button[data-id="view"] {
            padding: 5px 7px;
            &:after {
                @include font-awesome-icon("\f06e"); // fa-eye
            }
        }

        button[data-id="archive"] {
            padding: 5px 7px;
            &:after {
                @include font-awesome-icon("\f00d"); // fa-times
            }
        }

        button[data-id="unarchive"] {
            padding: 5px 6px;
            &:after {
                @include font-awesome-icon("\f1da"); // fa-history
            }
        }

        button[data-id="edit"] {
            padding: 7px 3px 3px 7px;
            &:after {
                @include font-awesome-icon("\f044"); // fa-edit
            }
        }

        button[data-id="practice"] {
            padding: 7px 3px 3px 7px;
            &:after {
                @include font-awesome-icon("\f12e"); // fa-puzzle-piece
            }
        }

        button[data-id="delete"] {
            padding: 5px 7px;
            &:after {
                @include font-awesome-icon("\f2ed", $FONT_AWESOME_REGULAR); // fa-trash-alt
            }
        }

        button[data-id="user"] {
            padding: 5px 7px;
            &:after {
                @include font-awesome-icon("\f2ed", $FONT_AWESOME_REGULAR); // fa-trash-alt
            }
        }

        button[data-id="save"] {
            padding: 5px 7px;
            &:after {
                @include font-awesome-icon("\f0c7"); // fa-save
            }
        }
    }

    tr.archived td:not(.actions) {
        opacity: 0.3;
    }
}

.locale-selector {
    @include set-selectize-max-width(200px);
}
