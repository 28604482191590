editable-things-list {
    // this is the same as what is set in bootstrap _tables.scss,
    // but we need it in two places in this file, and consistency within
    // this file is more important than consistency with _tables.scss,
    // so we override their styles.
    $thBottomPadding: 5px;

    .nav {
        margin-top: 10px;
    }

    .list-header {
        & > .nav {
            .pagination-container {
                display: flex;

                .results-per-page-container {
                    select[name="results-per-page"] {
                        height: 31px;
                        width: 75px;
                    }
                }

                .advanced-column-display-mode {
                    display: flex;
                    align-items: center;
                    height: 30px;
                    font-size: 14px;

                    button {
                        display: inline-flex;
                        align-items: center;
                        margin-right: 5px;
                        color: $COLOR_V3_BEIGE_BEYOND_DARK;
                        border: 1px solid $COLOR_V3_BEIGE_BEYOND_DARK;
                        border-radius: 3px;
                        background-color: transparent;
                        font-weight: 600;
                        height: 100%;

                        &:hover {
                            background-color: $COLOR_V3_BEIGE_LIGHTER;
                        }
                    }

                    i {
                        margin-right: 5px;
                    }

                    p {
                        margin-bottom: 0;
                        margin-left: 5px;
                        color: $COLOR_V3_BEIGE_BEYOND_DARK;
                    }

                    &.active {
                        button.toggle-button {
                            color: $COLOR_V3_BLUE;
                            border: 1px solid $COLOR_V3_BLUE;
                            background-color: $COLOR_V3_BLUE_LIGHTER;

                            &:hover {
                                background-color: rgba($COLOR_V3_BLUE_LIGHT, 0.5);
                            }
                        }
                    }
                }
            }
        }
    }

    .frozen-cols-wrapper-1 {
        width: 100%;
        position: relative;
    }
    .frozen-cols-wrapper-2 {
        width: 100%;
        overflow-x: auto; // see comment in positionStickyScrollbar
    }

    .table {
        tbody {
            tr {
                cursor: pointer;
                user-select: text !important;

                & > td {
                    vertical-align: middle;
                }
            }
        }
    }

    th {
        padding-bottom: $thBottomPadding;
        font-weight: $FONT_WEIGHT_SEMIBOLD;

        .tooltip-inner {
            white-space: pre-wrap;
            text-align: left;
        }

        .tooltip-anchor {
            cursor: pointer;

            &:hover {
                opacity: 0.7;
            }
        }
    }

    td {
        &[data-type="perc1"],
        &[data-type="number"] {
            text-align: center;
        }

        // center input boxes
        input {
            margin: 0px auto;
        }
    }

    td.beige {
        color: $COLOR_V3_BEIGE_DARKER;
    }

    // Then `frozen` class is attached to cells based on the
    // configuration in the columns array.  `displaying-as-frozen`
    // is only added to frozen columns when the table is actually
    // wide enough to require that they appear as frozen.
    td.displaying-as-frozen {
        z-index: 2;
    }

    th.displaying-as-frozen {
        z-index: 3;

        // This ensures that, if the frozen column headers are 1-line
        // and some other headers are multiple lines, then the text
        // in the frozen header gets pushed down as expected.  See
        // adminCohortEnrollmentStatus
        > span {
            position: absolute;
            bottom: $thBottomPadding;
            display: block;
        }
    }
    td.displaying-as-frozen,
    th.displaying-as-frozen {
        background-color: white;
        // javascript will set the position to absolute dynamically

        &.last-frozen-cell-on-left {
            border-right: 1px solid $COLOR_V3_BLACK;
        }
        &.first-frozen-cell-on-right {
            border-left: 1px solid $COLOR_V3_BLACK;
        }
    }

    table .checkbox,
    table .input {
        display: flex;
        justify-content: space-around;
        margin-right: 5px;

        input,
        input[type="checkbox"] {
            margin: 0 auto;
        }

        input[type="checkbox"] {
            position: relative;

            &::before {
                top: -1px; // aligns the visible checkbox with the clickable checkbox element
            }

            &[disabled] {
                /*
                    Disabled elements prevent tooltips that are set on their element from showing up,
                    so a common workaround is to place the disabled element in a wrapper element and
                    place the tooltip on the wrapper. Unfortunately, disabled elements prevent pointer
                    events from bubbling up to parent elements, which prevents the tooltip on the wrapper
                    from showing up. Setting 'pointer-events' to 'none' allows the events to bubble up
                    to the wrapper element, which then triggers the tooltip.
                    See https://github.com/angular-ui/bootstrap/issues/1025#issuecomment-60536946
                */
                pointer-events: none;
            }
        }
    }

    .search-and-export-box {
        font-size: 14px;
        .export-link {
            margin-right: 10px;
            line-height: 34px;
            color: $COLOR_V3_CORAL;
            vertical-align: middle;
        }

        .search-form {
            display: inline-block;
            vertical-align: top;
            input {
                line-height: 30px;
                border-radius: 17px;
            }
        }
    }

    .sticky-scrollbar {
        position: absolute;
        width: 100%;
        height: 10px;
        overflow-x: auto;
        overflow-y: hidden;
        z-index: 3;

        > div {
            opacity: 0;
        }
    }

    // This duplicates the definition inside of
    // .front-royal-form-container .form-group.row.  In moving to
    // react, we're removing the .front-royal-form-container wrapper
    // from some instances of editable-things-list.  In those cases, though
    // we still want this spacing. So we define it here.
    .editable-things-container {
        padding: 15px 56px 0px 56px;
    }
}
