.frame-author-comments {
    user-select: text !important;

    .expand-note {
        font-size: 12px;
        margin-top: 5px;
    }

    .comment-container {
        margin: 10px 0;
        margin-bottom: 0px;

        .comment-text,
        button[name="remove_comment"],
        input[name="completed"] {
            display: inline-block;
            vertical-align: middle;
        }

        button[name="remove_comment"],
        input[name="completed"] {
            margin: -15px 5px 0px 5px !important;
        }

        .comment-text {
            width: 100%;

            &.edit-mode {
                width: 260px;
            }

            > p {
                padding: 5px;
                border: 1px solid $COLOR_V3_BEIGE;
                border-radius: 3px;
                background-color: $COLOR_V3_BEIGE;
                word-break: break-word; // don't allow comments to mess with panel width
                margin-bottom: 0px;
            }

            .attribution {
                font-style: $FONT_STYLE_ITALIC;
                font-size: 85%;
                color: $COLOR_V3_GREY;
                float: left;
                margin-left: 5px;
            }
        }

        button[name="remove_comment"] {
            width: 15px;
            height: 15px;
            padding: 0;
            line-height: 15px;
        }
    }

    .comment-text.mine {
        > p {
            background-color: $COLOR_V3_WHITE;
            border: 1px solid $COLOR_V3_BEIGE_DARK;
            text-align: right;
        }

        > .attribution {
            float: right;
            margin-right: 5px;
        }
    }

    .comment-text.completed {
        > p {
            text-decoration: line-through;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            cursor: pointer;
        }
    }

    .comment-text.expanded {
        > p {
            text-decoration: line-through;
            cursor: pointer;
        }
    }

    .new-comment-container {
        margin-top: 20px;
        border: 1px solid $COLOR_V3_BEIGE_DARK;
        border-radius: 3px;
        padding: 10px;
        width: 100%;

        textarea[name="author_comment"] {
            width: 100%;
            height: 60px;
            margin-bottom: -5px;
        }

        button {
            float: right;
        }

        &:after {
            content: ".";
            display: block;
            height: 0;
            clear: both;
            visibility: hidden;
        }
    }

    button[name="toggle_edit_comment"] {
        margin-left: 0 !important;
    }
}
