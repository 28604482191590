.batch-lesson-grader {
    user-select: text !important;

    margin: 30px;

    table {
        position: relative;
    }

    .header {
        height: 80px;
    }

    .table-wrapper {
        .filter-labels {
            position: relative;
            text-align: center;

            &:before {
                content: "";
                display: block;
                position: absolute;
                top: calc(50% - 1px);
                left: 0;
                height: 2px;
                width: 100%;
                background-color: $COLOR_V3_BEIGE_DARK;
                z-index: 10;
            }

            .filter-label {
                position: relative;
                display: inline-block;
                margin: 0 auto;
                background: $COLOR_V3_BEIGE;
                padding: 5px;
                z-index: 100;
            }
        }

        .child-checkboxes {
            margin-left: 20px;
            margin-top: -5px;
            margin-bottom: 10px;
        }

        .long-frames-col {
            width: 50%;
            .frame-long-text {
                h3 {
                    font-size: 13px;
                }
                font-size: 12px;
                .well {
                    padding: 5px;
                }
                .filtered-text {
                    display: inline-block;
                    padding: 5px;
                }
            }
        }
    }

    .sortable-list {
    }

    .detailed-grade {
        .close-link,
        h3 {
            display: inline-block;
        }
        .close-link {
            margin-right: 10px;
        }
        grade-frame-list {
            display: block;
            margin-top: 15px;
            padding: 15px 15px 15px 15px;
            background: $COLOR_V3_WHITE;
        }
    }
}
