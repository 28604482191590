cf-has-text-or-image-editor {
    display: block;
    .cf-has-text-or-image-editor {
        .text_editor_wrapper {
            width: 180px;
            display: inline-block;
        }

        select[name="current_image"] {
            max-width: 90px;
        }
    }
}
