.edit_lesson_main_form {
    .panel {
        margin: 0px;
        margin-bottom: 1px;
        border-color: $COLOR_V3_PLUM;
    }

    input[type="text"] {
        width: 100%;
    }

    button.flat {
        white-space: normal; // we have some buttons in here with long text
    }

    button.editor_button {
        display: inline-block;
        width: 47%;
        background-color: $COLOR_V3_BEIGE_DARKEST;

        &.save_metadata {
            color: $COLOR_V3_WHITE;
            background-color: $COLOR_V3_GREEN;
            border-color: $COLOR_V3_GREEN;

            &:hover,
            &:hover:enabled,
            &:active,
            &:active:enabled,
            &:focus {
                background-color: $COLOR_V3_GREEN;
            }
        }
    }

    #lesson-description-panel {
        .well {
            padding-top: 5px;
            padding-bottom: 5px;
        }

        .lesson-description-container {
            position: relative;
            padding-right: 60px;

            .row-control-buttons {
                position: absolute;
                right: 0px;
                top: 3px;
                display: block;

                button {
                    font-size: 10px;
                    width: auto;
                    height: auto;
                    padding: 3px;

                    i:before {
                        margin-left: 0px;
                    }
                }
            }
        }
    }

    #translatable-export-panel {
        .translatable-buttons {
            margin-bottom: 12px;

            button {
                width: 100%;
            }
        }
    }
}
