@import "oreo/global/colors_v3";

.annotations-editor-container {
    padding: 5px;

    .annotation-container {
        label {
            user-select: none;

            input,
            p:not(.sub-text) {
                display: inline;
            }

            input {
                margin-right: 10px;
            }

            p {
                font-weight: bold;
                font-size: 16px;
            }

            p.sub-text {
                font-weight: normal;
                font-size: 14px;
                color: $COLOR_V3_GREY;
                margin-bottom: 5px;
            }
        }

        textarea {
            padding: 3px;
            font-size: 14px;
        }

        &:not(:last-of-type) textarea {
            margin-bottom: 15px;
        }
    }
}
