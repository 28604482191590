@mixin edit-frame-list-info-panel() {
    .panel {
        margin: 0px;
        margin-bottom: 1px;
        border-color: $COLOR_V3_PLUM;
    }
    .panel-heading {
        background: $COLOR_V3_PLUM;
        padding: 0;

        .panel-title {
            button {
                width: 100%;
                text-align: left;
                color: $COLOR_V3_WHITE;
                background: none;
                border: none;
                padding: 2px;
                outline: 0;
                cursor: pointer;

                &.inline {
                    display: inline-block;
                    width: auto;
                }
            }
        }
    }

    .panel-body {
        display: block;
        padding: 2px;
    }

    .panel-group {
        clear: both;
    }

    .info_panel {
        padding: 6px;

        .help-link,
        .grade-link {
            float: right;
        }

        dd {
            margin-bottom: 10px;
        }

        select[name="frame_type"] {
            width: 100%;
        }

        textarea {
            width: 100%;
            resize: vertical;
        }

        textarea:disabled {
            background-color: lightgray;
            color: gray;
        }

        input:disabled {
            background-color: lightgray;
            color: gray;
        }

        textarea[name="frameJson"] {
            width: 100%;
            height: 200px;
        }

        table {
            width: 100%;
        }

        .section_wrapper {
            // leave room for scrollbar
            width: 300px;
        }

        .section.text {
            textarea {
                width: 100%;
                height: 100px;
            }
        }

        .section.main-image {
            input {
                width: 100%;
            }
        }

        table.buttons,
        table.blanks {
            td.text_input {
                width: 50%;

                input,
                textarea {
                    width: 100%;
                }
                textarea {
                    resize: vertical;
                }
            }

            td.move {
                text-align: center;
                width: 36px;
                cursor: pointer;

                &:after {
                    content: "\2195";
                }
            }

            td.correctness.correct {
                &:after {
                    content: "\2713";
                }
            }
        }

        table.blanks {
            td.text_input {
                width: 100%;
            }
        }

        table.images {
            td.text_input {
                width: 122px;

                input {
                    width: 100%;
                }
            }
        }

        table.buttons,
        table.blanks,
        table.images {
            td.little_button {
                width: 22px;
            }
        }

        .image_upload_progress_bar {
            height: 12px;
            background-color: $COLOR_V3_GREEN_DARK;
        }

        .drag_instructions {
            color: $COLOR_V3_GREY_LIGHT;
        }

        .image-thumbnail-container {
            width: 25px;
            display: inline-block;
            text-align: center;

            .cf-image {
                max-height: 1.5em;
                max-width: 100%;
                display: inline;
            }
        }

        .interactive-image-title-context {
            color: darken($COLOR_V3_GREY_DARK, 10%);
            font-size: 80%;
            .image-thumbnail-container.cf-image {
                max-height: 0.9em;
                vertical-align: bottom;
            }
        }
    }
}
