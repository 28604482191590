.interactive-image-select {
    .interactive-cards-row {
        select {
            max-width: 60%;
        }

        hr {
            margin: 15px 0;
            background-color: $COLOR_V3_GREY_DARK;
        }
    }
}
