.grade-frame-list {
    color: $COLOR_V3_EGGPLANT;

    .modal-popup [label] {
        $size: 26px;
        $borderWidth: 1px;
        border: $borderWidth solid $COLOR_V3_WHITE;
        border-radius: 50%;
        display: inline-block;
        width: $size;
        height: $size;
        text-align: center;
        line-height: $size - 2 * $borderWidth;
        margin-left: 8px;
        background-color: $COLOR_V3_EGGPLANT;
        color: $COLOR_V3_WHITE;
    }

    .header {
        margin-top: -15px;
        margin-bottom: 10px;
        padding-top: 10px;
        padding-bottom: 15px;
        background-color: $COLOR_V3_CORAL;
        color: $COLOR_V3_WHITE;
        position: relative;
        padding-left: 75px;

        .letter-grade {
            $size: 60px;
            $borderWidth: 0px;
            position: absolute;
            top: calc(50% - #{$size} / 2);
            left: 15px;
            color: $COLOR_V3_CORAL;
            background-color: $COLOR_V3_WHITE;
            border-radius: 50%;
            width: $size;
            height: $size;
            text-align: center;
            line-height: $size - 2 * $borderWidth;
            font-size: 30px;
            margin-right: 12px;
        }

        .modal-popup [label] {
            background-color: $COLOR_V3_CORAL;
        }

        &.passingGrade {
            background-color: $COLOR_V3_GREEN;
            .letter-grade {
                color: $COLOR_V3_GREEN;
            }
            .modal-popup [label] {
                background-color: $COLOR_V3_GREEN;
            }
        }
    }
}
