resize-handle .resize {
    transform: rotate(90deg);
    cursor: pointer;

    &:after {
        @include font-awesome-icon("\f065"); // fa-expand
        color: $COLOR_V3_WHITE;
        font-size: 16px;
        text-shadow: 0px 0px 2px $COLOR_V3_BLACK, 0px 0px 5px $COLOR_V3_BLACK, 0px 0px 7px $COLOR_V3_BLACK;
    }
}
