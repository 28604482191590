.lesson-save-popup {
    .panel {
        border-color: $COLOR_V3_PLUM;
    }

    .panel-collapse {
        padding: 5px;
    }

    .panel-heading {
        background-color: $COLOR_V3_PLUM;
        color: $COLOR_V3_WHITE;
        background-image: none;
    }

    input[type="text"],
    textarea {
        width: 100%;

        &.ng-touched.ng-invalid-required {
            border-color: $COLOR_V3_RED;
        }
    }

    button.editor_button {
        width: 100%;
        display: block;
        color: $COLOR_V3_WHITE;
        margin: 0px auto 8px !important; // more important that editor_button

        &.submit {
            background-color: $COLOR_V3_PURPLE;
        }

        &.cancel {
            background-color: $COLOR_V3_RED;
        }
    }

    .buttons {
        margin-top: 20px;
    }

    .warning-message {
        color: $COLOR_V3_CORAL;
        font-size: 15px;
    }
}
