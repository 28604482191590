lesson-diff {
    display: block;
    position: absolute;
    width: 100%;
    min-height: 100%;
    background-color: $COLOR_V3_WHITE;
    padding: 15px;
    margin: 0px;

    del {
        color: $COLOR_V3_RED;
    }

    ins {
        color: $COLOR_V3_GREEN;
    }

    div {
        user-select: text !important;
    }

    .versions-row {
        text-align: center;
        .version {
            display: inline-block;
            .version-title {
                font-weight: $FONT_WEIGHT_SEMIBOLD;
            }
        }
        .arrow-col {
            display: inline-block;
            margin: 10px;
            vertical-align: top;
            top: 20px;
            position: relative;
        }
    }

    .frame-table {
        table-layout: fixed;

        .frame-th,
        .frame-td {
            width: 10%;
        }
        .diff-th,
        .diff-td {
            width: 90%;
        }
        .frame-td {
            font-weight: $FONT_WEIGHT_SEMIBOLD;
        }
    }

    $height: 30px;
    .frame-version-labels {
        margin-right: 10px;
        display: inline-block;
        vertical-align: top;

        .frame-version-label {
            margin-bottom: 40px;
            height: $height;
            display: block;
            font-size: 18px;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            padding-top: 5px;
            text-align: center;

            .date {
                display: block;
                font-size: 13px;
                font-style: $FONT_STYLE_ITALIC;
                font-weight: normal;
            }
        }
    }

    .frames {
        position: relative;
        display: inline-block;
        height: 160px;
        vertical-align: top;

        canvas {
            position: absolute;
            left: 0px;
            top: 0px;
        }

        .frames-list {
            margin-bottom: 40px;
            height: $height;
            display: inline-block;
        }
    }

    .frame {
        width: $height;
        height: $height;
        border: 1px solid transparent;
        display: inline-block;
        margin: 0px 12px 0px 0px;
        text-align: center;
        line-height: $height;
        font-size: 14px;

        background-color: $COLOR_V3_BEIGE;

        &.added {
            color: $COLOR_V3_WHITE;
            background-color: $COLOR_V3_GREEN;
        }

        &.removed {
            color: $COLOR_V3_WHITE;
            background-color: $COLOR_V3_RED;
        }

        &.edited {
            color: $COLOR_V3_WHITE;
            background-color: $COLOR_V3_BLUE;
        }
    }

    .checkbox {
        &.indented {
            margin-left: 18px;
        }
    }
}
