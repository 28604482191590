.cf-multiple-choice-challenge-editor {
    select {
        &[name="correct_answer"] {
            &[disabled] {
                opacity: 0.5;
            }
        }
    }

    .challenge-answers {
        margin-top: 5px;

        .cf-answer-list-editor {
            padding-left: 5px;
        }
    }

    .correct-answers-multiple {
        width: 100% !important;

        label {
            font-weight: normal;
            display: block;

            input {
                margin: 5px;
            }
        }
    }

    .messages {
        /* need to be super-specific with css path to override
		.edit_lesson .frame_list .info_panel table */
        table.messages_table {
            width: 100%;
            table-layout: fixed;

            td.answer_label {
                width: 100px;
                overflow: hidden;
                white-space: nowrap;
                padding-left: 8px;
            }

            td.input {
                padding-left: 4px;
            }
        }
    }
}
