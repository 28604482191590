locale-pack-selectize,
period-stream-selector {
    .icon {
        display: inline-block;
        margin-right: 6px;
    }

    .title {
        cursor: ns-resize;

        &:hover {
            background-color: rgba($COLOR_V3_BLUE_LIGHT, 0.2);
        }
    }

    .sortable-chosen {
        background-color: rgba($COLOR_V3_BLUE_LIGHT, 0.4);
        cursor: ns-resize;
    }

    .stream-entry > div {
        display: inline-block;
        vertical-align: top;
    }
}

period-stream-selector {
    $requiredColWidth: 100px;
    $captionColWidth: 200px;
    $colGap: 10px;
    margin-bottom: 10px;

    .selectize-control {
        margin-bottom: 10px;
    }

    .stream-row {
        display: block;
        width: 100%;
        padding: 5px 10px;
        border-bottom: 1px solid $COLOR_V3_BEIGE;

        &:first-child {
            border-top: 1px solid $COLOR_V3_BEIGE;
        }

        &:last-child {
            margin-bottom: 10px;
        }

        &.readonly {
            padding: 0px;

            .stream-title-col {
                padding-left: 0px;
            }
        }

        .stream-col {
            display: inline-block;
            vertical-align: middle;
        }
    }

    .stream-title-col {
        position: relative;
        width: calc(100% - #{$requiredColWidth + $captionColWidth + 2 * $colGap});
        margin-right: $colGap;
        padding-left: 40px;

        .icons {
            position: absolute;
            display: block;
            left: 0px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .stream-required-col {
        width: $requiredColWidth;
        margin-right: $colGap;

        .required-readonly {
        }

        .not-required-readonly {
            font-weight: $FONT_WEIGHT_NORMAL;
            opacity: 0.5;
        }
    }

    .stream-caption-col {
        width: $captionColWidth;
        label {
            font-weight: $FONT_WEIGHT_NORMAL;

            &.no-caption {
                opacity: 0.5;
            }
        }
    }

    .required-checkbox {
    }
}
