.component_editor {
    position: relative;

    $COMPONENT_EDITOR_BUTTON_WIDTH: 23px;

    button {
        vertical-align: top;

        &:disabled {
            .icon {
                opacity: 0.2;
            }
        }

        &.stretchable {
            width: auto;
            padding: 0px 2px;
        }

        &.remove-button {
            background-color: $COLOR_V3_RED_LIGHT;
            &[disabled] {
                background-color: $COLOR_V3_GREY_LIGHTER;
                color: $COLOR_V3_GREY_DARK;
            }
        }

        &.add-button {
            margin-left: auto;
            margin-right: 0;
            background-color: $COLOR_V3_GREEN_LIGHT;
            &[disabled] {
                background-color: $COLOR_V3_GREY_LIGHTER;
                color: $COLOR_V3_GREY_DARK;
            }
        }

        &.inline {
            display: inline-block;
        }

        &.block {
            display: block;
        }
    }

    td.button_wrapper {
        width: $COMPONENT_EDITOR_BUTTON_WIDTH + 3px;
    }

    .icon {
        margin: 0 auto;

        &:hover {
            opacity: 0.5;
        }

        &.inline {
            margin-right: 5px;
        }

        &.arrow-up {
            &:before {
                @include font-awesome-icon("\f062"); // fa-arrow-up
            }
        }

        &.arrow-down {
            &:before {
                @include font-awesome-icon("\f063"); // fa-arrow-down
            }
        }

        &.remove {
            &:before {
                @include font-awesome-icon("\f2ed", $FONT_AWESOME_REGULAR); // fa-trash-alt
            }
        }

        &.edit {
            &:before {
                @include font-awesome-icon("\f044"); // fa-edit
            }
        }

        &.add {
            &:before {
                @include font-awesome-icon("\f196"); // fa-plus-square-o
                cursor: pointer; // why does this not work?
            }
        }

        &.unchecked {
            &:before {
                @include font-awesome-icon("\f096"); // fa-square-o
            }
        }

        &.checked {
            &:before {
                @include font-awesome-icon("\f046"); // fa-check-square-o
            }
        }

        &.checked {
            &:before {
                @include font-awesome-icon("\f046"); // fa-check-square-o
            }
        }

        &.image {
            &:before {
                @include font-awesome-icon("\f03e"); // fa-font
            }
        }

        &.text {
            &:before {
                @include font-awesome-icon("\f031"); // fa-check-square-o
            }
        }

        &.vmove {
            &:before {
                @include font-awesome-icon("\2195");
            }
        }

        &.expand {
            &:before {
                @include font-awesome-icon("\f067"); // fa-plus
            }
        }

        &.unexpand {
            &:before {
                @include font-awesome-icon("\f068"); // fa-minus
            }
        }

        &.undo {
            &:before {
                @include font-awesome-icon("\f0e2"); // fa-undo
            }
        }

        &.lock {
            &:before {
                @include font-awesome-icon("\f023"); // fa-arrow-up
            }
        }

        &.unlock {
            &:before {
                @include font-awesome-icon("\f09c"); // fa-arrow-up
            }
        }

        &.grade {
            &:before {
                @include font-awesome-icon("\f091"); // fa-trophy
            }
        }

        &.hint {
            &:before {
                @include font-awesome-icon("\f05a"); // fa-info-circle
            }
        }

        &.upload {
            &:before {
                @include font-awesome-icon("\f093"); // fa-upload
            }
        }

        &.align-left {
            &:before {
                @include font-awesome-icon("\f036");
            }
        }

        &.align-center {
            &:before {
                @include font-awesome-icon("\f037");
            }
        }

        &.align-right {
            &:before {
                @include font-awesome-icon("\f038");
            }
        }
    }

    .icon-button-group {
        float: right;

        &.left {
            float: left;
        }
    }
}
