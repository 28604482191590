.edit-lesson-preview-mode {
    font-size: $FONT_SIZE_CONTENT;

    *,
    *:after,
    *:before {
        box-sizing: border-box;
    }

    $width: 800px;
    $border: 2px;
    $paddingLeft: 11px;
    $paddingTop: 15px;
    $iPhoneWidth: 360px;
    $iPhoneHeight: 820px;
    // this is to account for screen lost to browser and phone UI
    $iPhoneOffset: 20px;

    $monitorWidth: $width + 4 * $border + 2 * $paddingLeft;

    .screens-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        min-width: 1200px;
        max-width: 1300px;
        margin: 0 auto;
    }

    .monitor {
        width: $monitorWidth;
        position: absolute;
        bottom: 0px;
        top: 0px;
        left: 0px;
        height: auto;
        padding-top: $paddingTop;
        padding-left: $paddingLeft;
        border: $border solid #bcbcbc;
        border-radius: 15px;
        background: #fff;
        //transition: all .5s ease-in-out 0s;
        max-height: $monitorWidth;
        min-height: 300px;
    }

    // move to center of screen if only showing monitot
    &.show_desktop .monitor {
        left: 50%;
        transform: translateX(-50%);
    }

    .monitor .iframe-wrapper {
        position: absolute;
        border: 2px solid #bcbcbc;
        top: $border + $paddingTop;
        bottom: $border + $paddingTop;
        left: $border + $paddingLeft;
        right: $border + $paddingLeft;
        border-radius: 3px;
    }

    .monitor iframe {
        position: relative;
        height: 100%;
        width: 100%;
        border: none;
        padding: 0px;
        margin: 0px;
    }

    .phone {
        width: $iPhoneWidth + (2 * $paddingLeft) + (4 * $border);
        height: $iPhoneHeight + $paddingTop;
        padding-top: $paddingTop;
        padding-left: $paddingLeft;
        padding-right: $paddingLeft;
        position: absolute;
        right: 0px;
        top: 0px;
        bottom: 0px;
        border: $border solid #bcbcbc;
        border-radius: 15px;
        background: #fff;
        // transition: all .5s ease-in-out 0s;
    }

    // move to center of screen if only showing iphone
    &.show_iphone .phone {
        left: 50%;
        right: auto;
        transform: translateX(-50%);
    }

    .phone .iframe-wrapper {
        border: $border solid #bcbcbc;
        width: $iPhoneWidth + (2 * $border);
        height: $iPhoneHeight - $iPhoneOffset + (2 * $border);
        border-radius: 3px;
    }

    .phone iframe {
        width: 100%;
        height: 100%;
        border: none;
    }

    // positioned such that "Edit Lesson" button appears
    // in the same real estate as the "Preview Lesson" button
    .actions {
        position: fixed;
        bottom: 10px;
        right: 16px;
    }

    .show-select {
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 10001; // Above the header
    }

    .loading {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;

        .slowly {
            animation: bounceInUp;
            animation-duration: 1s;

            button {
                display: block;
                margin: 12px auto !important; // even more important than the one defined on editor_button
            }
        }
    }
}
