.cf-text-editor {
    input[name="fontSize"] {
        width: 37px;
    }

    .text-toolbar {
        display: flex;
        justify-content: space-between;
        padding: 2px 1px 5px 1px;

        button {
            min-width: 30px;
            font-family: "ApercuPro", "Arial", sans-serif;
            font-weight: bold;
            font-size: 14px;
            line-height: 23px;
            flex-grow: 1;
            margin-right: 3px;
            padding: 1px 3px;

            &:last-of-type {
                margin-right: 0px;
            }
        }
    }

    .counter-and-render-toggle {
        display: flex;
        justify-content: space-between;
        margin: 0 3px 3px 3px;

        .tlm-counter {
            padding: 0px 5px;
            color: #a0a0a0;
            background-color: #eeeeee;
            display: inline-block;
            border-radius: 3px;
            display: flex;
            align-items: center;

            &.tlm-warning {
                color: #a69b28;
                background-color: #fff6a8; // yellow-ish
            }
            &.tlm-over {
                color: #b55957;
                background-color: #ffb2ac; // red-ish
            }
        }

        .render-toggle {
            display: flex;
            align-items: center;
            cursor: pointer;
            user-select: none;

            input[type="checkbox"] {
                margin: 0;
                pointer-events: none;
            }

            h5 {
                color: #a0a0a0;
                font-size: 10px;
                letter-spacing: 1.3;
                font-weight: bold;
                text-transform: uppercase;
                margin: 0 10px 0 0;
                text-align: right;
            }
        }
    }
}
