.modal.pin-and-publish-modal {
    .modal-dialog {
        width: 900px;
        .modal-content .modal-body {
            padding: 15px;
        }
    }
}

.pin-and-publish-lesson {
    color: $COLOR_V3_EGGPLANT;

    .should-publish-wrapper {
        text-align: center;
        margin: 12px 0px;
    }

    .pre-pin-checklist {
        .row {
            margin: 6px 0px 0px 0px;

            > div {
                padding: 0px;
            }

            > div:nth-of-type(1) {
                text-align: right;
                padding-right: 8px;
            }
        }
    }
}
