frame-diff {
    .removed {
        color: $COLOR_V3_RED;
    }

    .added {
        color: $COLOR_V3_GREEN;
    }

    .comment,
    .annotation {
        .header {
            font-style: $FONT_STYLE_ITALIC;
            color: $COLOR_V3_GREY;
        }
        .far {
            color: $COLOR_V3_BLACK;
            margin-right: 5px;
        }
        .text {
            display: block;
            margin-left: 24px;
        }
    }

    .annotation {
        margin-bottom: 5px;
    }

    table.indent {
        margin-left: 24px;
    }

    .section {
        margin-bottom: 32px;
    }

    td.usage {
        min-width: 125px;
        padding-right: 12px;
    }

    .text-list {
        margin: 10px 0px;
        display: inline-block;
        vertical-align: middle;
        &.arrow {
            margin: 20px;
        }
    }

    .image-changes {
        img {
            max-width: 200px;
            max-height: 200px;
            display: inline;
        }

        td.image {
            width: 200px;
            text-align: center;
            vertical-align: middle;
        }

        .fa-arrow-right {
            font-size: 36px;
        }
    }
}
