content-item-edit-locale {
    display: block;
    margin-bottom: 12px;

    .translation-selectors {
        .left-col {
            padding-right: 0px;
        }
        .right-col {
            padding-left: 8px;
            padding-right: 0px;
        }
    }
}
